<template>
  <div>
    <h1 class="text-2xl font-bold mb-5">Repayments</h1>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :data="repayments"
        :columns="columns"
        :actions="actions"
        :loading="loading"
        :dropdown="false"
        ref="table"
      />
    </div>

    <modal
      className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-12 px-12"
      ref="paymentModal"
    >
      <h3 class="text-xl font-bold mb-16">Make Payment</h3>

      <div>
        <template v-if="errorMessage">
          <div
            class="rounded-full bg-red-100 p-3 flex flex-row items-center -mt-10 mb-10"
          >
            <div
              class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3"
            >
              !
            </div>
            <div class="text-xs font-normal">{{ errorMessage }}</div>
          </div>
        </template>

        <form-group
          :left-icon="false"
          v-model="amountDueInCurrency"
          :disabled="true"
        >
          Amount Due
        </form-group>

        <!-- <div class="flex flex-row">

					<div class="w-3/10">
						<form-group
							type="text"
							:options="paymentOptions"
							:left-icon="false"
							name="percentage"
							:form="form"
							v-model="percentage"
							disabled
						>
							To Pay
						</form-group>
					</div>
					<div class="w-7/10 pl-4">
						<form-group
							:left-icon="false"
							v-model="amountToPayInCurrency"
							:disabled="true"
						/>
					</div>

				</div> -->

        <form-group
          type="select"
          :options="paymentMethods"
          :left-icon="false"
          name="payment_method"
          :form="form"
          v-model="paymentMethod"
        >
          Payment Method
        </form-group>

        <div class="text-center -mt-8">
          <template v-if="paymentMethod === 'paystack'">
            <paystack
              :amount="amountToPay"
              type="button"
              class="button bg-blue-500 text-white hover:bg-blue-600"
              :callback="paymentSuccessful"
              :disabled="form.loading"
            >
              <template v-if="form.loading">Verifying...</template>
              <template v-else>Make Payment</template>
            </paystack>
          </template>
          <template v-else-if="paymentMethod === 'bank_transfer'">
            <button
              type="button"
              class="button bg-blue-500 text-white hover:bg-blue-600"
              @click.prevent="showBankTransferModal"
              :disabled="form.loading"
            >
              <template v-if="form.loading">Verifying...</template>
              <template v-else>Make Payment</template>
            </button>
          </template>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-12 px-12"
      ref="bankTransferModal"
    >
      <h3 class="text-xl font-bold mb-16">Payment Information</h3>

      <form @submit.prevent="makeBankTransferRequest">
        <template v-if="errorMessage">
          <div
            class="rounded-full bg-red-100 p-3 flex flex-row items-center -mt-10 mb-10"
          >
            <div
              class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3"
            >
              !
            </div>
            <div class="text-xs font-normal">{{ errorMessage }}</div>
          </div>
        </template>

        <div class="grid grid-cols-2 gap-8">
          <div class="col-span-1">
            <form-group
              :left-icon="false"
              right-icon="copy-outline"
              :right-icon-click="() => copyText(amountToPay)"
              :disabled="true"
              v-model="amountToPayInCurrency"
            >
              To Pay
            </form-group>
          </div>
          <div class="col-span-1">
            <form-group
              :left-icon="false"
              right-icon="copy-outline"
              :right-icon-click="() => copyText(accountNumber)"
              :disabled="true"
              v-model="accountNumber"
            >
              Account Number
            </form-group>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-8">
          <div class="col-span-1">
            <form-group
              :left-icon="false"
              :form="form"
              right-icon="copy-outline"
              :right-icon-click="() => copyText(accountNumber)"
              :disabled="true"
              v-model="accountName"
            >
              Account Name
            </form-group>
          </div>
          <div class="col-span-1">
            <form-group
              :left-icon="false"
              right-icon="copy-outline"
              :right-icon-click="() => copyText(bankName)"
              :disabled="true"
              v-model="bankName"
            >
              Bank Name
            </form-group>
          </div>
        </div>

        <form-group
          :left-icon="false"
          right-icon="copy-outline"
          :right-icon-click="() => copyText(paymentReference)"
          :disabled="true"
          v-model="paymentReference"
        >
          Payment Reference
        </form-group>

        <div class="grid grid-cols-2 gap-2 -mt-8">
          <button
            type="submit"
            class="button w-full px-5 bg-blue-500 text-white hover:bg-blue-600"
            :disabled="form.loading"
          >
            <template v-if="form.loading">Verifying...</template>
            <template v-else>Mark as Paid</template>
          </button>

          <button
            type="button"
            class="button w-full px-5 border border-blue-500 text-blue-500 hover:bg-blue-600 hover:text-white"
            :disabled="form.loading"
          >
            <template v-if="form.loading">Verifying...</template>
            <template v-else>Contact Support</template>
          </button>
        </div>
      </form>
    </modal>

    <modal
      className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-24 px-24 text-center"
      ref="bankTranserSuccessModal"
    >
      <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark" />

      <div class="text-lg font-bold mb-4">Transfer Request Recorded!</div>
      <div class="text-sm font-thin mb-20">
        Please wait while our support team approves your payment.
      </div>

      <button
        type="button"
        class="button border border-blue-500 hover:bg-blue-600 text-blue-500 hover:text-white px-16"
        @click.prevent="$refs.bankTranserSuccessModal.close"
      >
        Close
      </button>
    </modal>

    <modal
      className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-24 px-24 text-center"
      ref="paymentSuccessModal"
    >
      <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark" />

      <div class="text-lg font-bold mb-4">Payment Successful!</div>
      <div class="text-sm font-thin mb-20">
        Your repayment has been marked as paid.
      </div>

      <button
        type="button"
        class="button border border-blue-500 hover:bg-blue-600 text-blue-500 hover:text-white px-16"
        @click.prevent="$refs.paymentSuccessModal.close"
      >
        Close
      </button>
    </modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      actions: [
        {
          text: 'Pay',
          class:
            'px-5 py-2 rounded-sm bg-blue-500 hover:bg-blue-600 text-white',
          action: this.pay,
          show: (repayment) => repayment.status !== 'paid',
          disabled: (repayment) => repayment.status === 'transferred',
        },
      ],
      checkmark: require('@/assets/checkmark-base.svg'),
      company: null,
      searchQuery: '',
      loading: false,
      repayment: null,
      repayments: [],
      columns: [
        {
          name: 'amount',
          th: 'Amount',
          render: (repayment, amount) =>
            `₦ ${this.$options.filters.currency(amount)}`,
        },
        {
          name: 'status',
          th: 'Status',
          render: (repayment) => {
            switch (repayment.status) {
              case 'paid':
                return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Paid
									</div>`;
              case 'transferred':
                return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Pending Approval
									</div>`;
              case 'not_due':
                return `<div class="rounded-sm border border-blue-300 text-blue-300 bg-blue-100 text-xs px-3 py-2 inline-block">
										Not Due
									</div>`;
              case 'due':
                return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Due
									</div>`;
              default:
                if (repayment.defaulted) {
                  return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
											Default
										</div>`;
                }
                break;
            }
          },
        },
        {
          name: 'due_date',
          th: 'Due Date',
        },
        {
          name: 'batch',
          th: 'Batch',
          render: (repayment) => repayment.settlement?.name,
        },
      ],
      form: this.$options.basicForm([
        { name: 'percentage', value: '100' },
        { name: 'payment_method', value: 'paystack' },
      ]),
      paymentOptions: [
        // {title: '25%', value: 25},
        // {title: '50%', value: 50},
        // {title: '75%', value: 75},
        { title: '100%', value: 100 },
      ],
      paymentMethods: [
        { title: 'Card Payment via Paystack', value: 'paystack' },
        // { title: 'Card Payment via Flutterwave', value: 'rave' },
        { title: 'Bank Transfer', value: 'bank_transfer' },
      ],
      paymentMethod: 'paystack',
      percentage: '100',
    };
  },
  computed: {
    accountName() {
      return 'Crednet Technologies';
    },
    accountNumber() {
      return '0815690344';
    },
    amountDue() {
      return this.repayment?.amount;
    },
    amountDueInCurrency() {
      return `₦ ${this.$options.filters.currency(this.amountDue)}`;
    },
    amountToPay() {
      return this.amountDue * (this.form.data.percentage.value / 100);
    },
    amountToPayInCurrency() {
      return `₦ ${this.$options.filters.currency(this.amountToPay)}`;
    },
    bankName() {
      return 'Access Bank';
    },
    errorMessage() {
      if (this.form.error.toString().match(/Error: Network Error/i)) {
        return 'Please check your internet connection';
      }

      if (this.form.error?.response?.data?.message) {
        return this.form.error.response.data.message;
      }

      const errors = this.form.error?.response?.data?.error;
      if (errors && Object.keys(errors).length) {
        return 'Please check the form for incorrect or missing data';
      }

      return null;
    },
    paymentReference() {
      const date = new Date().format('dd/mm/Y');
      const random = Math.round(Math.random() * 100000);

      return `${this.user?.name}—${date}-${random}`;
    },
  },
  beforeMount() {
    this.getRepayments();
  },
  methods: {
    async getRepayments() {
      this.loading = true;
      await this.$get({
        url: `${this.$baseurl}/staff/repayments/${this.user.id}`,
        headers: this.headers,
        success: (response) => {
          this.repayments = response.data.repayments;
        },
      });
      this.loading = false;
    },
    pay(repayment) {
      this.repayment = repayment;
      this.$refs.paymentModal.open();
    },
    async makeBankTransferRequest() {
      this.form.loading = true;
      await this.$post({
        url: `${this.$baseurl}/staff/repayments/transfer/${this.user.company_id}`,
        headers: this.headers,
        data: {
          reference: this.paymentReference,
          repayment: this.repayment.id,
        },
        success: (response) => {
          console.log(response);
          this.$refs.paymentModal.close();
          this.$refs.bankTransferModal.close();
          this.$refs.bankTranserSuccessModal.open();
          this.getRepayments();
        },
        error: (error) => {
          this.form.error = error;
        },
      });
      this.form.loading = false;
    },
    async paymentSuccessful(response) {
      const { reference } = response;
      console.log(response);
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/staff/repayments/pay/${this.user.id}`,
        headers: this.headers,
        data: {
          reference,
          repayment: this.repayment.id,
        },
        success: (response) => {
          // this.settlements = response.data.settlements;
          console.log(response);
          this.$refs.paymentSuccessModal.open();
          this.$refs.paymentModal.close();
          this.$refs.bankTransferModal.close();
          this.getRepayments();
        },
      });
      this.loading = false;
    },
    showPaymentModal() {
      this.$refs.paymentModal.open();
    },
    showBankTransferModal() {
      this.$refs.paymentModal.close();
      this.$refs.bankTransferModal.open();
    },
  },
};
</script>
